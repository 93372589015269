@import "~normalize.css/normalize.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,700&subset=latin-ext");
@import url("https://rsms.me/inter/inter.css");

html {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  background: #f5f5f5;
  color: #1a1a1a;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 22px;
  font-weight: bold;
}

h2 {
  font-size: 16px;
  font-weight: bold;
}

button {
  font-size: 12px;
}

button,
select,
input,
textarea {
  font-family: "Inter", sans-serif;
}

a {
  color: #0084ca;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  width: 160px;
  bottom: 100%;
  left: 50%;
  margin-bottom: 10px;
  margin-left: -80px;
  padding: 5px 10px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 10px;
  line-height: 150%;
  color: #2c1338;
  text-align: left;
  font-weight: normal;
  opacity: 0;
  pointer-events: none;
  transition: 200ms opacity ease-in-out;
  text-transform: none;
}

[data-tooltip]:hover::after {
  opacity: 1;
}
